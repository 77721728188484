import React from "react";
import './FavoritesPage.css';
import {ButtonBase, Icon, CircularProgress, Modal, InputBase, IconButton} from "@mui/material";
import {Tools} from "../../tools/Tools";
import {withRouter} from "../../tools/withRouter";
import undraw_empty from "./../../assets/undraw_empty.svg";

import Swiper from 'swiper';
import 'swiper/css';

class FavoritesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            mode:"ROUTES",
            routes:[],
            stops:[],
            loadingData:false,

            stopSearchDisplayed:false,
            stopSearchQuery:"",
            searching:false,
            stopSearchResults:[],
        }
    }

    selectMode(mode){
        this.setState({
            mode:mode,
        })
        if(mode === "STOPS"){this._swiper.slideNext()}
        else{this._swiper.slidePrev()}
    }

    componentDidMount(){
        this.loadRoutesAndFavorites()
        this._swiper = new Swiper(".swiper",{cssMode:true});
        this._swiper.on("activeIndexChange",(e)=>{
            this.setState({mode:this._swiper.activeIndex === 0 ? "ROUTES" : "STOPS"})
        })
    }

    goBack(){
        this.props.router.navigate(-1)
    }

    loadRoutesAndFavorites(){
        this.setState({loadingData:true})

        this.getRoutes()
            .then((routes)=>{

                let favoriteRoutes = this.getFavoriteRoutes();

                for(let route of routes){
                    let favMatch = favoriteRoutes.find((fr)=>{return fr.real_route_id === route.real_route_id});
                    route.favorite = !!favMatch
                }

                this.setState({
                    loadingData:false,
                    routes:routes,
                })
            })
            .catch((err)=>{
                console.log(err);
                this.setState({loadingData:false})
            })
    }

    getRoutes():Promise<any>{
        return new Promise((resolve,reject)=>{
            fetch("https://impulsyon.api.hubup.cloud/passenger/information/agency/get/by/code/" + global.parameters.tag)
                .then((response) => {
                    return response.json()
                })
                .then((agencies) => {
                    let agency = agencies[0];
                    return fetch(agency.server_url + "/passenger/information/agency/get/all/available/route")
                })
                .then((response) => {
                    return response.json()
                })
                .then((routes)=>{
                    resolve(Tools.getSortedRoutes(routes));
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    getFavoriteRoutes(){
        let favorites = [];
        let favoritesData = localStorage.getItem("wlpi@favoritesRoutes");
        if(favoritesData && favoritesData.length > 0){
            try{favorites = JSON.parse(favoritesData)} catch(e){console.log(e)};
        }
        return favorites
    }

    toggleRouteFavorite(route){
        let favoriteRoutes = this.getFavoriteRoutes();
        let added = route.favorite !== true;
;        if(route.favorite === true){favoriteRoutes = favoriteRoutes.filter((fr)=>{return fr.real_route_id !== route.real_route_id})}
        else{favoriteRoutes.push({id:route.id, route_short_name:route.route_short_name, route_long_name:route.route_long_name, route_color:route.route_color, real_route_id:route.real_route_id,})}

        localStorage.setItem("wlpi@favoritesRoutes",JSON.stringify(favoriteRoutes))

        let message = added ? "La ligne a été ajoutée à vos favoris" : "La ligne a été retirée de vos favoris"
        global.emitter.emit("displaySnackBar",{message:message,key:new Date().getTime()})

        this.reloadFavoriteRoutes();

    }

    reloadFavoriteRoutes(){
        let routes = this.state.routes.slice();
        let favoriteRoutes = this.getFavoriteRoutes();
        for(let route of routes){
            let favMatch = favoriteRoutes.find((fr)=>{return fr.real_route_id === route.real_route_id});
            route.favorite = !!favMatch
        }
        this.setState({routes:routes})
    }

    render(){
        return(
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
                <div style={{width:"100%", height:60, borderBottom:"1px solid #EFEFEF", backgroundColor:"#FFFFFF",
                display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                    <ButtonBase style={{height:60, width:60, display:"flex", justifyContent:"center", alignItems:"center"}} onClick={()=>{this.goBack()}}>
                        <Icon style={{color:"#555555", fontSize:24}}>arrow_backward</Icon>
                    </ButtonBase>
                    <span style={{color:"#222222", fontSize:19, fontWeight:"bold", paddingLeft:4}}>Favoris</span>
                    <div style={{flex:1}}/>
                    <ButtonBase style={{height:60, width:60, display:"flex", justifyContent:"center", alignItems:"center"}}
                                className={this.state.mode === "STOPS" ? "favorites-mode-search favorites-mode-search-displayed" : "favorites-mode-search"}
                                onClick={()=>{this.openStopSearch()}}>
                        <Icon style={{color:"#555555", fontSize:24}}>search</Icon>
                    </ButtonBase>
                </div>
                <div style={{width:"100%", height:44, borderBottom:"1px solid #EEEEEE", backgroundColor:"#FFFFFF", display:"flex",
                    flexDirection:"row", justifyContent:"flex-start", alignItems:"center", overflowX:"hidden", paddingRight:12}}>

                    <ButtonBase className={this.state.mode === "ROUTES" ? "favorites-mode-button favorites-mode-button-selected" : "favorites-mode-button"}
                                onClick={()=>{this.selectMode("ROUTES")}}>
                        <span>{"LIGNES"}</span>
                        <div className={"favorites-mode-button-bottom-bar"}/>
                    </ButtonBase>
                    <ButtonBase className={this.state.mode === "STOPS" ? "favorites-mode-button favorites-mode-button-selected" : "favorites-mode-button"}
                                onClick={()=>{this.selectMode("STOPS")}}>
                        <span >{"ARRÊTS"}</span>
                        <div className={"favorites-mode-button-bottom-bar"}/>
                    </ButtonBase>
                    <div style={{flex:1}}/>
                    {this.state.loadingData &&
                        <CircularProgress size={20} style={{color:"#1079FC"}}/>
                    }
                </div>
                <div style={{width:"100%", height:"calc(100% - 104px)",backgroundColor:"#FAFAFA", position:"relative"}} className={"swiper"}>

                    <div className="swiper-wrapper">
                        <div style={{width:"100%", height:"100%", overflowY:"auto"}} className="swiper-slide">
                            {this.renderRoutes()}
                        </div>
                        <div style={{width:"100%", height:"100%", overflowY:"auto"}} className="swiper-slide">
                            {this.renderStops()}
                        </div>
                    </div>
                </div>
                {this.renderStopSearch()}
            </div>
        )
    }

    renderRoutes(){
        return(
            <div style={{width:"100%"}}>
                {this.state.routes.map((route)=>{
                    return(
                        <div className={"route-fav-row"} key={"route-" + route.id}>

                            <div style={{padding:4, border:"1px solid #222222", display:"flex", justifyContent:"center", alignItems:"center",
                            paddingLeft:8, paddingRight:8, backgroundColor:"#" + route.route_color, borderRadius:4}}>
                                <span style={{color:"#FFFFFF", fontWeight:"bold", fontSize:14}} className={"text-bordered noselect"}>{route.route_short_name}</span>
                            </div>

                            <p style={{flex:1, color:"#555555", margin:0, textAlign:"left", fontSize:12, paddingLeft:12, paddingRight:8}} className={"noselect"}>
                                {route.route_long_name}
                            </p>

                            <ButtonBase style={{height:54, width:54, display:"flex", justifyContent:"center", alignItems:"center"}} onClick={()=>{this.toggleRouteFavorite(route)}}>
                                <Icon className={route.favorite ? "favorite-route-icon favorite-route-icon-fav" : "favorite-route-icon"}>{route.favorite ? "star" : "star_border"}</Icon>
                            </ButtonBase>

                        </div>
                    )
                })}
            </div>
        )
    }

    renderStops() {
        return (
            <div style={{width: "100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", borderLeft:"1px solid #EFEFEF"}}>
                <img alt={"no-stop-fav"} src={undraw_empty} className={"no-stop-fav"}/>
                <p style={{width:200, maxWidth:"100%", textAlign:"center", margin:0, color:"#797979", fontSize:14, marginTop:25}}>
                    Vous n'avez ajouté aucun arrêt à votre liste de favoris
                </p>
            </div>
        )
    }

    openStopSearch(){
        this.setState({
            stopSearchDisplayed:true
        })
    }

    closeStopSearch(){
        this.setState({
            stopSearchQuery:"",
            stopSearchDisplayed:false,
            searching:false,
            stopSearchResults:[],
        })
    }

    renderStopSearch(){
        return(

            <Modal
                open={this.state.stopSearchDisplayed}
                onClose={()=>{this.closeStopSearch()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{width:"100%", height:"100%", padding:5, pointerEvents:"all"}}>
                    <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", marginBottom:8}}>
                        <div style={{width:50, height:50, backgroundColor:"#FFFFFF", pointerEvents:"all", borderRadius:12, marginRight:8,
                        overflow:"hidden"}} className={"floating-shadowed"}>
                            <ButtonBase style={{height:"100%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}} onClick={()=>{this.closeStopSearch()}}>
                                <Icon style={{color:"#555555", fontSize:24}}>arrow_back</Icon>
                            </ButtonBase>
                        </div>
                        <div style={{flex:1, height:50, backgroundColor:"#FFFFFF", pointerEvents:"all", borderRadius:12, display:"flex",
                        flexDirection:"row", justifyContent:"flex-start", alignItems:"center", overflow:"hidden", paddingRight:4}} className={"floating-shadowed"}>
                            <InputBase
                                style={{ flex:1, height:"100%", paddingLeft:12 }}
                                autoFocus
                                placeholder="Rechercher un arrêt"
                                value={this.state.stopSearchQuery}
                                onChange={(e)=>{this.onQueryChange(e.target.value)}}
                            />
                            <IconButton onClick={()=>{this.onQueryChange("")}} disabled={this.state.stopSearchQuery.length === 0}>
                                <Icon className={this.state.stopSearchQuery.length > 0 ? "clear-search-icon" : "clear-search-icon clear-search-icon-disabled"}>clear</Icon>
                            </IconButton>
                        </div>
                    </div>

                    {this.state.stopSearchQuery &&
                        <div style={{width:"100%", backgroundColor:"#FFFFFF", borderRadius:12, overflow:"hidden"}} className={"floating-shadowed"}>
                            <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center'}}>
                                <div style={{height:40, width:40, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    {this.state.searching && <CircularProgress size={20} style={{color:"#1079FC"}}/>}
                                    {!this.state.searching && <Icon style={{color:"#797979", fontSize:18}}>keyboard_arrow_down</Icon>}
                                </div>
                                <p style={{flex:1, paddingLeft:2, margin:0, paddingRight:5, color:"#555555", fontSize:12}}>
                                    {this.state.searching ? "Recherche en cours" : "Résultats de la recherche"}
                                </p>
                            </div>

                            <div style={{width:"100%", borderTop:"1px solid #EFEFEF"}}>

                                {this.state.stopSearchResults.length === 0 &&
                                    <div style={{width:"100%", height:80, display:"flex", justifyContent:"center", alignItems:"center", padding:12}}>
                                        {this.state.searching === false &&
                                            <p style={{width:"100%", textAlign:"center", margin:0, fontSize:14, color:"#797979"}}>
                                                Désolé, aucun arrêt ne correspond aux critères de votre recherche
                                            </p>
                                        }
                                    </div>
                                }

                            </div>


                        </div>
                    }


                </div>

            </Modal>

        )
    }

    onQueryChange(query){
        this.setState({
            stopSearchQuery:query
        },()=>{
            if(this._searchTimeout){
                clearTimeout(this._searchTimeout);
                this._searchTimeout = null;
            }
            this.initSearchTimeout(query)
        })
    }

    _searchTimeout:any;

    initSearchTimeout(query){
        this._searchTimeout = setTimeout(()=>{
            if(query.length > 0){this.searchStops(query);}
            else{this.clearStopSearchResults()}
        },150)
    }

    searchStops(query){
        this.setState({searching:true})

        setTimeout(()=>{
            this.setState({
                searching:false,
                stopSearchResults:[]
            })
        },350)
    }

    clearStopSearchResults(){
        this.setState({
            searching:false,
            stopSearchResults:[],
        })

    }


}

export default withRouter(FavoritesPage)
