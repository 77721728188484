import React from "react";
import {Icon, IconButton, Slide, Snackbar} from "@mui/material";

export default class SnackBar extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state={
            open:false,
            message:"",
            key:null,
        }
    }

    _displaySnackBarSubscription:any;

    componentDidMount(){
        if(this._displaySnackBarSubscription){this._displaySnackBarSubscription.remove()}
        this._displaySnackBarSubscription = global.emitter.addListener("displaySnackBar",(data)=>{
            this.openSnackBar(data.message,data.key)
        })
    }

    componentWillUnmount() {
        if(this._displaySnackBarSubscription){this._displaySnackBarSubscription.remove()}
    }

    openSnackBar(message,key){
        this.setState({
            open:true,
            message:message,
            key:key
        })
    }

    closeSnackBar(){
        this.setState({open:false})
    }

    render(){
        return(
            <Snackbar
                key={this.state.key ? this.state.key : undefined}
                open={this.state.open}
                autoHideDuration={3000}
                onClose={()=>{this.closeSnackBar()}}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                message={this.state.message}
                sx={{ bottom: { xs: 70, sm: 0 } }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={()=>{this.closeSnackBar()}}
                    >
                        <Icon style={{color:"#FFFFFF"}}>clear</Icon>
                    </IconButton>
                }
            />
        )
    }

}
