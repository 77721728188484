import React from "react";
import './App.css';
import logo_rdta from "./assets/rdta/logo_rdta.png"
import {ButtonBase, Icon} from "@mui/material";
import {BrowserRouter as Router, Routes, Route, Navigate, NavLink} from "react-router-dom";
import {EventEmitter} from "fbemitter"
import SnackBar from "./tools/components/SnackBar";

// Pages
import HomePage from "./pages/home/HomePage";
import LivemapPage from "./pages/livemap/LivemapPage";
import PollsPage from "./pages/polls/PollsPage";
import FavoritesPage from "./pages/favorites/FavoritesPage";


export default class App extends React.Component{

    constructor(props){
        super(props)
        this.state={
        }

        this.init();
    }

    init(){
        global.emitter = new EventEmitter()
        this.initCompany();
    }

    initCompany(){
        global.parameters = {
            tag:"TEMP1",
            name:"Régie départementale des transports des Ardennes",
            logo:logo_rdta,
            primaryColor:"#0EAADE",
            primaryBackgroundColor:"#70c0da",
            accentColor:"#00A58A",
            secondAccentColor:"#7B4D92",
            allowedRoutes:[
                {
                    path:"/",
                    label:"Accueil",
                    icon:"home",
                },
                {
                    path:"/livemap",
                    label:"Carte",
                    icon:"timeline",
                },
                {
                    path:"/polls",
                    label:"Enquêtes",
                    icon:"poll",
                },
                {
                    path:"/favorites",
                    label:"Favoris",
                    icon:"star",
                },
            ]
        }
    }

    render(){

        const parameters = global.parameters;

        return(
            <Router>
                <div className={"app-wrapper"}>
                    <div className={"app-router-wrapper"}>
                        <Routes>
                            <Route index path={"/"} element={<HomePage/>}/>
                            <Route index path={"/livemap"} element={<LivemapPage/>}/>
                            <Route index path={"/polls"} element={<PollsPage/>}/>
                            <Route index path={"/favorites"} element={<FavoritesPage/>}/>
                            <Route path={"*"} element={<Navigate replace to={"/"}/>}/>
                        </Routes>
                        <SnackBar/>
                    </div>
                    <div className={"app-navigation-wrapper"}>
                        {parameters.allowedRoutes.map((route)=>{
                            return(
                                <NavLink className={"app-navigation-button-link"}
                                         to={route.path} key={"route:" + route.path}>
                                    <ButtonBase className={"app-navigation-button"}>
                                        <Icon className={"app-navigation-button-icon"}>{route.icon}</Icon>
                                        <span className={"app-navigation-button-text"}>{route.label}</span>
                                    </ButtonBase>
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
            </Router>
        )
    }
}
