import React from "react";
import './PollsPage.css';
import {ButtonBase, Icon} from "@mui/material";
import {withRouter} from "../../tools/withRouter";
import poll from "../../assets/poll.svg";

class PollsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            loadingData:false,
        }
    }

    goBack(){
        this.props.router.navigate(-1)
    }

    render(){
        return(
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"column"}}>
                <div style={{width:"100%", height:60, borderBottom:"1px solid #EFEFEF", backgroundColor:"#FFFFFF",
                    display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>

                    <ButtonBase style={{height:60, width:60, display:"flex", justifyContent:"center", alignItems:"center"}} onClick={()=>{this.goBack()}}>
                        <Icon style={{color:"#555555", fontSize:24}}>arrow_backward</Icon>
                    </ButtonBase>

                    <span style={{color:"#222222", fontSize:19, fontWeight:"bold", paddingLeft:4}}>Enquête de statisfaction</span>
                </div>
                <div style={{width:"100%", height:"calc(100% - 60px)",backgroundColor:"#FAFAFA", position:"relative"}}>
                    <div style={{width: "100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <img alt={"no-stop-fav"} src={poll} className={"no-stop-fav"}/>
                        <p style={{width:200, maxWidth:"100%", textAlign:"center", margin:0, color:"#797979", fontSize:14, marginTop:25}}>
                            Aucune enquête de statisfaction en cours pour le moment
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(PollsPage)
