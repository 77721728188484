import React from "react";
import './LivemapPage.css';

export default class LivemapPage extends React.Component {

    render(){
        return(
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <iframe id={"iframe-iv"} height={"100%"} width={"100%"} style={{border:"none"}} src={"https://livemap.hubup.cloud/temp1?embedded=true"}/>
            </div>
        )
    }

}
