export class Tools{

    static now(){
        return new Date().toISOString().split('.')[0] + "Z"
    }

    static getSortedRoutes(routes) {
        let numberedRoutes = [];
        let textedRoutes = [];
        let allRoutes = [];

        for (let route of routes) {
            if (isNaN(route.route_short_name)) {
                textedRoutes.push(route)
            } else {
                numberedRoutes.push(route);
            }
        }

        numberedRoutes = numberedRoutes.sort((a, b) => {
            let numA = parseFloat(a.route_short_name);
            let numB = parseFloat(b.route_short_name);
            if (numA < numB)
                return -1;
            if (numA > numB)
                return 1;
            return 0;
        });

        textedRoutes = textedRoutes.sort((a, b) => {
            if (a.route_short_name < b.route_short_name)
                return -1;
            if (a.route_short_name > b.route_short_name)
                return 1;
            return 0;
        });

        allRoutes = allRoutes.concat(numberedRoutes).concat(textedRoutes);

        return allRoutes;
    }


}
