import React from "react";
import './HomePage.css';
import {ButtonBase, Icon, Paper} from "@mui/material";
import logo_rdta from "./../../assets/rdta/logo_rdta.png"
import grand_est from "./../../assets/rdta/grand_est.svg"
import moment from "moment";
import {withRouter} from "../../tools/withRouter";

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            currentRealTimeVehiclesCount:0,
        }
    }

    componentDidMount(){
        this.getRealTimeVehicleCount();
        this.startInterval()
    }

    componentWillUnmount(){
        this.clearInterval()
    }

    _interval:any;

    startInterval(){
        this._interval = setInterval(()=>{
            this.getRealTimeVehicleCount();
        },5*60*1000)
    }

    clearInterval(){
        if(this._interval){clearInterval(this._interval)}
        this._interval= null;
    }

    async getRealTimeVehicleCount(){
        const nowMinusThreeMinutes = moment().subtract(3, "minutes")
        const positionsCount = await this.getLastRealTimePositions(nowMinusThreeMinutes)
        console.log(positionsCount)

        this.setState({
            currentRealTimeVehiclesCount:positionsCount
        })
    }

    getLastRealTimePositions( minDateTime: any): Promise<any> {
        return new Promise((resolve, reject) => {
            fetch("https://impulsyon.api.hubup.cloud/passenger/information/agency/get/by/code/" + global.parameters.tag)
                .then((resp)=>{return resp.json()})
                .then((piAgencies)=>{
                    return fetch(piAgencies[0].server_url + "/exposed/pi/lasts/" + moment(minDateTime).toISOString().split('.')[0] + "Z/position")
                })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    let res = 0;
                    if(data.positions){
                        for (let rawPosition of data.positions) {
                            let operationMatch = data.metaData.operations.find((o) => {
                                return o.event_id === rawPosition.displayed_event
                            });
                            if (!operationMatch) {
                                continue;
                            }
                            let revisionDataMatch = data.metaData.revision_data.find((rd) => {
                                return rd.real_trip_id === operationMatch.real_trip_id
                            });
                            if (!revisionDataMatch) {
                                continue;
                            }
                            res++;
                        }
                    }
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    goToMap(){
        this.props.router.navigate("/livemap")
    }

    render(){
        return(
            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}} className={"homepage-wrapper"}>

                <div style={{width:"100%", height:100, position:"absolute", left:0, top:6, right:0, overflow:"hidden", display:"flex",justifyContent:"center",
                    alignItems:"center", paddingBottom:8, zIndex:8}}>
                    <div style={{minWidth:"180vw", height:100, backgroundColor:global.parameters.secondAccentColor, borderBottomLeftRadius:"60%", borderBottomRightRadius:"60%"}}
                         className={"card-shadow"}/>
                </div>
                <div style={{width:"100%", height:100, position:"absolute", left:0, top:0, right:0, overflow:"hidden", display:"flex",justifyContent:"center",
                alignItems:"center", paddingBottom:8, zIndex:9}}>
                    <div style={{minWidth:"180vw", height:100, backgroundColor:"#FFFFFF", borderBottomLeftRadius:"60%", borderBottomRightRadius:"60%"}}
                         className={"card-shadow"}/>
                </div>

                <div style={{width:"100%", height:100, position:"absolute", left:0, top:0, right:0, overflow:"hidden", display:"flex",justifyContent:"flex-start",
                    alignItems:"center", paddingBottom:8, zIndex:12, flexDirection:"row", paddingLeft:12, paddingRight:18}}>

                    <img src={logo_rdta} style={{height:40}}/>
                    <div style={{flex:1}}/>
                    <img src={grand_est} style={{height:38}}/>

                </div>

                <div style={{width:"100%", height:"100%", zIndex:5,  padding:8, overflowY:"auto"}}>

                    <div style={{height:120}}/>

                    <p style={{margin:0, marginBottom:8, paddingLeft:8, color:global.parameters.secondAccentColor, fontSize:12, fontWeight:"bold"}}>Mon bus en temps réel</p>
                    <Paper style={{width:"100%", backgroundColor:"#FFFFFF", borderRadius:8, overflow:"hidden", marginBottom:30, padding:4}}>
                        <ButtonBase style={{height:120, width:"100%", cursor:"pointer", borderRadius:4}} className={"map-background"}
                        onClick={()=>{this.goToMap()}}>

                            <div style={{position:"absolute", left:10, top:10}}>
                                <div style={{position:"relative"}}>
                                    <div className={"pulsating-alert-circle"}/>
                                    <div style={{width:40, height:40, backgroundColor:"#FFFFFF", borderRadius:20, left:0, top:0, zIndex:60, position:"absolute", display:"flex",
                                        justifyContent:"center", alignItems:"center", border:"2px solid #1079FC"}}>
                                        <Icon style={{color:"#1079FC", fontSize:24, marginBottom:-1}}>directions_bus</Icon>
                                    </div>
                                </div>
                            </div>

                            {this.state.currentRealTimeVehiclesCount > 0 &&
                                <Paper style={{height:30, minWidth:120, position:"absolute", top:15, left:60, zIndex:90, paddingLeft:5, paddingRight:5,
                                    display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", border:"2px solid #1079FC"}}>
                                    <p style={{color:"#1079FC", fontSize:14, margin:0, fontWeight:"bold"}}>{this.state.currentRealTimeVehiclesCount} Véhicules en temps réel</p>
                                </Paper>
                            }

                            <div style={{position:"absolute", left:0, bottom:0, right:0, height:34, display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"row"}}>
                                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",  padding:4,
                                    backgroundColor:"rgba(0,0,0,0.5)", borderRadius:4}}>
                                    <p style={{margin:0, color:"#FFFFFF", fontSize:14, textAlign:"center", }}>
                                        VOIR LA CARTE
                                    </p>
                                    <Icon style={{color:"#FFFFFF", fontSize:16, marginLeft:5}}>arrow_forward</Icon>
                                </div>

                            </div>

                        </ButtonBase>
                    </Paper>

                    <p style={{margin:0, marginBottom:8, paddingLeft:8, color:global.parameters.secondAccentColor, fontSize:12, fontWeight:"bold"}}>Dernières actualités:</p>
                    <Paper style={{width:"100%", backgroundColor:"#FFFFFF", borderRadius:4, overflow:"hidden", marginBottom:30}}>


                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                        padding:8}}>
                            <Icon style={{color:"#1079FC", marginRight:8}}>info</Icon>
                            <p style={{color:"#222222", fontSize:14, textAlign:"left", margin:0, fontWeight:"bold"}}>
                                CONDITIONS CLIMATIQUES DEGRADEES : RAPPEL DES PRATIQUES<br/>
                            </p>
                        </div>

                        <p style={{color:"#1079FC", fontWeight:"normal", fontSize:12, margin:0, paddingLeft:8, paddingBottom:4}}>Vendredi 10 Décembre 2021 à 09:05</p>

                        <div className={"alert-image"}>

                        </div>

                        <p style={{width:"100%", textAlign:"left", fontSize:12, margin:0, padding:8, paddingBottom:0}}>
                            Information du pôle transport du GRANDEST sur les pratiques en période d'intempérie.

                            2 cas se présentent : <br/> <br/>

                            <strong>1. Annulation complète des transports par arrêté préfectoral :</strong><br/> <br/>

                            La préfecture prend un arrêté interdisant la circulation totale de l’ensemble du réseau Routier Ardennais. Même si par endroit les routes sont praticables, l'arrêté concernera la majorité du département privilégiant la sécurité maximale. <br/> <br/>

                            <strong>2. En l’absence d’arrêté préfectoral</strong>, l’exécution des services de transports scolaires peut être localement assurée, adaptée ou supprimée. Dans ce cas de figure, <strong>c'est le transporteur qui sera en charge d’apprécier la faisabilité de ses circuits afin de garantir le transport en sécurité des enfants.</strong> <br/> <br/>

                            <strong>Pour rappel cependant, lorsque le circuit du matin n’est pas assuré, le transport du soir (ou du midi le mercredi) ne l’est pas non plus.</strong> <br/> <br/>

                            En d’autres termes, si les parents prennent l’initiative de déposer leurs enfants à l’école le matin, ils devront en assurer le retour du midi ou du soir et ce même si les conditions climatiques sont redevenues favorables. <br/> <br/>
                        </p>


                    </Paper>

                    {/*<div style={{height:200}}/>*/}


                </div>


            </div>
        )
    }

}

export default withRouter(HomePage)
